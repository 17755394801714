import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const custom = defineStyle({
    mt: "12 !important",
    fontFamily: "Inter Regular, sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "0.75rem !important",
    lineHeight: "1.05rem !important",
    color: "#AFAFAF !important",

    // mt: "0.5rem",
    // fontFamily: "Inter Regular, sans-serif",
    // fontWeight: 400,
    // fontSize: "0.75rem",
    // lineHeight: "1.05rem",
    // color: "#AFAFAF",
});

const variants = {
    custom: custom,
};

export const FormHelperText = defineStyleConfig({
    variants,
    defaultProps: {
        variant: "custom",
    },
});
