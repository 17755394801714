import * as yup from "yup";
import { CompanyApi } from "../../../apis";
import { APPC } from "../../../config";

export const loginPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        username: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email")),
        password: yup.string().required(t("cmn:val.required")),
    });

export const forgotPasswordPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        email: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email")),
    });

export const registerPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup.string().required(t("cmn:val.required")),
        domain: yup
            .string()
            .required(t("cmn:val.required"))
            .min(3, t("cmn:val.minLenght"))
            .max(20, t("cmn:val.maxLenght"))
            .matches(/^[a-z0-9-]*$/, t("ent.Company:domain.val.dataAllow"))
            .test(
                "isExist",
                t("ent.Company:domain.val.uniq"),
                async (value) => {
                    const domain = `${value}.${APPC.APP_DOMAIN}`;
                    return (
                        (
                            await CompanyApi.postCompanyExist<
                                { isExist: boolean },
                                { domain: string }
                            >({
                                domain: domain,
                            })
                        ).response?.isExist !== true
                    );
                }
            ),
        users: yup.array().of(
            yup.object().shape({
                firstName: yup.string().required(t("cmn:val.required")),
                lastName: yup.string().required(t("cmn:val.required")),
                email: yup
                    .string()
                    .required(t("cmn:val.required"))
                    .email(t("ent.User:email.val.email")),
                plainPassword: yup.string().required(t("cmn:val.required")),
                plainPasswordConfirm: yup
                    .string()
                    .required(t("cmn:val.required"))
                    .oneOf(
                        [yup.ref("plainPassword")],
                        t("ent.User:plainPasswordConfirm.val.match")
                    ),
            })
        ),
    });

export const setPasswordPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        plainPassword: yup.string().required(t("cmn:val.required")),
        plainPasswordConfirm: yup
            .string()
            .required(t("cmn:val.required"))
            .oneOf(
                [yup.ref("plainPassword")],
                t("ent.User:plainPasswordConfirm.val.match")
            ),
    });
