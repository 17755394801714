import { useRef } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";

type AppAlertProps = {
    children: JSX.Element;
    title: string;
    isOpen: boolean;
    onSuccess: () => Promise<void>;
    onCancel: () => void;
    successLable?: string;
    cancelLable?: string;
};

export const AppAlert = ({
    children,
    title,
    isOpen,
    onSuccess,
    onCancel,
    successLable,
    cancelLable,
}: AppAlertProps) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onCancel}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader>{title}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>{children}</AlertDialogBody>
                <AlertDialogFooter>
                    <Button
                        onClick={onCancel}
                        ref={cancelRef}
                        variant={"secondary"}
                    >
                        {cancelLable}
                    </Button>
                    <Button
                        onClick={() => {
                            onSuccess().then();
                            onCancel();
                        }}
                        ml={3}
                    >
                        {successLable}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
