import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContainerState, useTrans } from "../../../hooks";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
} from "@chakra-ui/react";
import { AppIcon, AppPageHeader } from "../../../components";
import { WorkspaceList as ListComponent } from "./WorkspaceList";
import { WorkspaceForm as FormComponent } from "./WorkspaceForm";
import { AppSearch } from "../../../containers";

export const WorkspacePage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const formContainerState = useFormContainerState();

    const [search, setSearch] = useState("");

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    return (
        <>
            <AppPageHeader title={t("padm.WorkspacePage:text.workspace")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    <Center height="2.1875rem">
                        <Divider orientation="vertical" />
                    </Center>
                    <Button
                        variant={"primary"}
                        onClick={() => formContainerState.open(0)}
                    >
                        <AppIcon name="ics-plus-circle" w="1rem" />
                        <Hide below="md">
                            <Text ml={2}>
                                {t("padm.WorkspacePage:button.addWorkspace")}
                            </Text>
                        </Hide>
                    </Button>
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    formContainerState={formContainerState}
                    search={search}
                />
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent formContainerState={formContainerState} />
            )}
        </>
    );
};
