import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { Task } from "./Task";

export class Project extends BaseEntity {
    public name: string = "";
    public description?: string = "";
    public tasks?: string[] | Task[];
    public company?: string | Company = "";

    constructor(model: Partial<Project> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PProject = Partial<Project>;
