import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class CompanyApi extends EntityApi {
    protected static GET_COLLECTION = "/api/companies";

    protected static POST_COLLECTION = "/api/companies";

    protected static GET_ITEM = "/api/companies/{id}";

    protected static PUT_ITEM = "/api/companies/{id}";

    protected static PATCH_ITEM = "/api/companies/{id}";

    protected static DELETE_ITEM = "/api/companies/{id}";

    protected static POST_COMPANY_EXIST = "/company-exist";

    protected static POST_COMPANY_REGISTER = "/company-register";

    protected static GET_COLLECTION_MY_COMPANY = "/my-company";

    public static async postCompanyExist<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_COMPANY_EXIST };
        return this.postCollection(entity, extraParams);
    }

    public static async postCompanyRegister<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_COMPANY_REGISTER };
        return this.postCollection(entity, extraParams);
    }

    public static async getCollectionMyCompany<R>(
        page = 1,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.GET_COLLECTION_MY_COMPANY };
        return this.getCollection(page, extraParams, cancelToken);
    }
}
