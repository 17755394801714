import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";

type AppFormControlProps = {
    children: JSX.Element;
    label?: string;
    isInvalid?: boolean;
    message?: string;
};

export const AppFormControl = ({
    children,
    label = "",
    isInvalid = false,
    message = "",
}: AppFormControlProps) => (
    <FormControl isInvalid={isInvalid}>
        {label && <FormLabel>{label}</FormLabel>}
        {children}
        {message && <FormErrorMessage>{message}</FormErrorMessage>}
    </FormControl>
);
