import { useState } from "react";

export type FormContainerStateReturnType = {
    isOpen: boolean;
    entityId: number | string;
    entityData: any;
    setEntityData: (data: any) => void;
    open: (entityId: number | string) => void;
    close: () => void;
};

export function useFormContainerState(
    onOpen?: (entityId: number | string) => void,
    onClose?: () => void
): FormContainerStateReturnType {
    const [isOpen, setIsOpen] = useState(false);
    const [entityId, setEntityId] = useState<number | string>(0);
    const [entityData, setEntityData] = useState<any>({});

    const open = (entityId: number | string = 0) => {
        setEntityId(entityId);
        setIsOpen(true);
        if (onOpen) {
            onOpen(entityId);
        }
    };

    const close = () => {
        setEntityId(0);
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return {
        isOpen,
        entityId,
        entityData,
        open,
        close,
        setEntityData,
    };
}
