import {
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Text,
    useDisclosure,
    Button,
    Select,
    PopoverCloseButton,
    FormLabel,
    Flex,
    HStack,
} from "@chakra-ui/react";
import { AppIcon } from "../../components";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const AppStopWatch = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [start, setStart] = useState<number>(0);
    const [displyTime, setDisplyTime] = useState("00:00:00");
    const [isRunning, setIsRunning] = useState(false);
    const [isActionDisable, setIsActionDisable] = useState(false);

    const updateTimer = () => {
        const secondsFromStart = dayjs().valueOf() - start;
        setDisplyTime(dayjs(secondsFromStart).utc().format("HH:mm:ss"));
    };

    const startStopHandler = () => {
        setIsActionDisable(true);
        setIsRunning(!isRunning);
        if (isRunning) {
            setStart(0);
            setDisplyTime("00:00:00");
        } else {
            setStart(dayjs().valueOf());
        }
        setTimeout(() => setIsActionDisable(false), 1000);
    };

    useEffect(() => {
        if (isRunning) {
            const timer = setTimeout(updateTimer, 1000);
            return () => clearTimeout(timer);
        }
    }, [displyTime, isRunning]);

    return (
        <>
            <Popover
                placement="bottom-start"
                offset={[0, -1]}
                isOpen={isOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    <Box
                        width="150px"
                        height="50px"
                        padding={4}
                        bg={isOpen ? "#f9f9f9" : "#FFF"}
                        border={"1px solid #E1E1E1"}
                        borderTopLeftRadius={"0.25rem"}
                        borderTopRightRadius={"0.25rem"}
                        borderBottomLeftRadius={isOpen ? 0 : "0.25rem"}
                        borderBottomRightRadius={isOpen ? 0 : "0.25rem"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        fontWeight={500}
                        cursor="pointer"
                        as="button"
                        zIndex={900}
                        borderBottom={isOpen ? "1px solid #f9f9f9" : ""}
                        onClick={isOpen ? onClose : onOpen}
                    >
                        <AppIcon name={"icl-clock"} w="1.25rem" />
                        <Text
                            fontSize={18}
                            mt={1}
                            fontFamily="Courier"
                            textAlign="center"
                        >
                            {displyTime}
                        </Text>
                    </Box>
                </PopoverTrigger>
                <PopoverContent
                    width="500px"
                    padding={6}
                    bg="#f9f9f9"
                    boxShadow="lg"
                    border={"1px solid #E1E1E1"}
                    borderTopLeftRadius={0}
                    borderTopRightRadius={"0.25rem"}
                    borderBottomLeftRadius={"0.25rem"}
                    borderBottomRightRadius={"0.25rem"}
                    zIndex={100}
                >
                    <PopoverCloseButton />
                    <PopoverBody padding={0}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <FormLabel>Project</FormLabel>
                                <Select placeholder="Select Project">
                                    <option value="option1">WorkPrio</option>
                                    <option value="option2">Appointment</option>
                                    <option value="option3">
                                        Leonie chaptal
                                    </option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel>Task</FormLabel>
                                <Select placeholder="Select Task">
                                    <option value="option1">
                                        Create Adminlayout UI component
                                    </option>
                                    <option value="option2">
                                        react-select UI adjustments as per the
                                        theme as per the theme as per the theme
                                    </option>
                                    <option value="option3">
                                        crud operations for User Entity
                                    </option>
                                </Select>
                            </Box>
                            <HStack justifyContent={"flex-end"} marginTop={4}>
                                <Button
                                    onClick={startStopHandler}
                                    size="sm"
                                    isDisabled={isActionDisable}
                                >
                                    {isRunning ? "STOP" : "START"}
                                </Button>
                            </HStack>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};
