import { Button, HStack, Text } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { NavLink } from "react-router-dom";

export const AppTab = () => {
    const tabItems = [
        {
            key: 1,
            label: "List",
            iconName: "icl-list",
            url: "/admin/dashboard",
        },
        {
            key: 2,
            label: "Kanban",
            iconName: "icl-clipboard",
            url: "/admin/dashboard",
        },
        {
            key: 3,
            label: "Table",
            iconName: "icl-chart-table",
            url: "/admin/dashboard",
        },
        {
            key: 4,
            label: "Grid",
            iconName: "icl-media-pause-square",
            url: "/admin/dashboard",
        },
        {
            key: 5,
            label: "Test",
            iconName: "icl-media-pause-square",
            url: "/admin/dashboard",
            isDisabled: true,
        },
    ];

    const activeTabIndex = 4;

    return (
        <>
            <HStack gap={2} ms={"6.25rem"} mt={"3.125rem"}>
                {tabItems.map((tab, index) => {
                    const isActive = tab.key === activeTabIndex;
                    return (
                        <NavLink to={tab.url} key={index}>
                            <Button
                                isActive={isActive}
                                variant={"tab"}
                                size={"md"}
                                isDisabled={tab.isDisabled ? true : false}
                            >
                                <HStack gap={2}>
                                    {tab?.iconName && (
                                        <AppIcon name={tab.iconName} w="1rem" />
                                    )}
                                    <Text as={"span"}>{tab.label}</Text>
                                </HStack>
                            </Button>
                        </NavLink>
                    );
                })}
            </HStack>
        </>
    );
};
