import { BaseEntity } from "./BaseEntity";
import { Project } from "./Project";

export class Task extends BaseEntity {
    public name: string = "";
    public description?: string = "";
    public project?: string | Project = "";

    constructor(model: Partial<Task> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PTask = Partial<Task>;
