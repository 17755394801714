import * as yup from "yup";
import { CompanyApi, UserApi } from "../../../apis";
import { APPC } from "../../../config";

export const schema = (
    t: (val: string) => string,
    isEditMode: boolean = false
) => {
    const companyValidation = {
        name: yup.string().required(t("cmn:val.required")),
        isActive: yup.bool(),
    };
    if (!isEditMode) {
        companyValidation["domain"] = yup
            .string()
            .required(t("cmn:val.required"))
            .min(3, t("cmn:val.minLenght"))
            .max(20, t("cmn:val.maxLenght"))
            .matches(/^[a-z0-9-]*$/, t("ent.Company:domain.val.dataAllow"))
            .test(
                "isExist",
                t("ent.Company:domain.val.uniq"),
                async (value) => {
                    const domain = `${value}.${APPC.APP_DOMAIN}`;
                    return (
                        (
                            await CompanyApi.postCompanyExist<
                                { isExist: boolean },
                                { domain: string }
                            >({
                                domain: domain,
                            })
                        ).response?.isExist !== true
                    );
                }
            );
    }
    const userValidation = {
        users: yup.array().of(
            yup.object().shape({
                firstName: yup.string().required(t("cmn:val.required")),
                lastName: yup.string().required(t("cmn:val.required")),
                email: yup
                    .string()
                    .required(t("cmn:val.required"))
                    .email(t("ent.User:email.val.email"))
                    .test(
                        "isExist",
                        t("ent.User:email.val.uniq"),
                        async (value) => {
                            return (
                                (
                                    await UserApi.postUserExist<
                                        { isExist: boolean },
                                        { email: string }
                                    >({
                                        email: value,
                                    })
                                ).response?.isExist !== true
                            );
                        }
                    ),
                plainPassword: yup.string().required(t("cmn:val.required")),
                plainPasswordConfirm: yup
                    .string()
                    .required(t("cmn:val.required"))
                    .oneOf(
                        [yup.ref("plainPassword")],
                        t("ent.User:plainPasswordConfirm.val.match")
                    ),
            })
        ),
    };
    if (isEditMode) return yup.object().shape({ ...companyValidation });
    return yup.object().shape({ ...companyValidation, ...userValidation });
};
