import { memo } from "react";
import { NavLink } from "react-router-dom";
import { Button, Divider, HStack, Text } from "@chakra-ui/react";
import { AppIcon } from "../../components";
import { NavItem } from "./AppNav";
import { ButtonVariants } from "../../theme/components/button";

interface AppNavItemProps {
    navItem: NavItem;
    variant?: ButtonVariants;
    onlyIcon?: boolean;
    isActive?: boolean;
}

const AppNavItem = ({
    navItem,
    variant,
    onlyIcon,
    isActive,
}: AppNavItemProps) => {
    return (
        <>
            <NavLink
                to={navItem?.url || ""}
                onClick={() => {
                    document
                        ?.querySelectorAll(".rotate-180")
                        .forEach((element) => {
                            element?.classList.remove("rotate-180");
                        });
                }}
            >
                <Button
                    isActive={isActive}
                    variant={variant}
                    className={onlyIcon ? "btn-icon-md" : ""}
                    size="sm"
                >
                    <HStack gap={5}>
                        {navItem.iconName && (
                            <AppIcon
                                name={navItem.iconName || ""}
                                color={isActive ? "#000000" : "#727272"}
                            />
                        )}
                        {!onlyIcon && (
                            <Text as={"span"} fontWeight="normal">
                                {navItem.label}
                            </Text>
                        )}
                    </HStack>
                </Button>
            </NavLink>
            {navItem?.divider && (
                <Divider
                    orientation="horizontal"
                    my={4}
                    borderColor={"#E4E4E4"}
                    alignSelf={"stretch"}
                />
            )}
        </>
    );
};

export default memo(AppNavItem, (prevProps, nextProps) => {
    return (
        prevProps.isActive === nextProps.isActive &&
        prevProps.onlyIcon === nextProps.onlyIcon
    );
});
