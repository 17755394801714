import { Button, HStack, Text, Flex, Box, Tooltip } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { AppTaskCard } from "./AppTaskCard";

export const AppTaskColumn = () => {
    return (
        <>
            <Flex
                w={"full"}
                direction={"column"}
                p={"1.25rem"}
                gap={"1.25rem"}
                border={"1px solid #E4E4E4"}
                borderRadius={"8px"}
                bg={"white"}
                justifyContent={"space-between"}
            >
                <HStack
                    gap={"1rem"}
                    flexWrap={"nowrap"}
                    justifyContent={"space-between"}
                >
                    <Tooltip
                        hasArrow
                        label="Ready For Testing"
                        bg="#444"
                        color="white"
                        placement="auto"
                        openDelay={1000}
                    >
                        <Text
                            noOfLines={1}
                            textStyle="mediumXl"
                            verticalAlign={"bottom"}
                            textOverflow={"ellipsis"}
                        >
                            Ready For Testing
                        </Text>
                    </Tooltip>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="30px"
                        height="30px"
                        borderRadius="30px"
                        bg="#727272"
                        color="white"
                        textAlign="center"
                        padding={"20px"}
                    >
                        <Text textStyle="regularSm">999</Text>
                    </Box>
                </HStack>
                <Button variant={"secondary"} size={"lg"}>
                    <AppIcon name="icl-plus" w="1.25rem" />
                    <Text marginLeft={2}>Create Task</Text>
                </Button>
                <AppTaskCard />
                <AppTaskCard />
                <AppTaskCard />
            </Flex>
        </>
    );
};
