import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrans } from "../../../hooks/useTrans";
import { registerPageSchema } from "./schema";
import {
    Button,
    HStack,
    Input,
    VStack,
    Box,
    Image,
    useToast,
    Center,
    InputGroup,
    InputRightAddon,
} from "@chakra-ui/react";
import { Company, CompanyApi } from "../../../apis";
import { AppFormControl, AppLoader } from "../../../components";
import { APPC, cPath } from "../../../config";
import logoSrc from "../../../assets/images/workprio_logo.svg";

export const RegisterPage = () => {
    // hooks
    const { t } = useTrans();
    const toast = useToast();

    // state
    const [loading, setLoading] = useState(false);

    // form
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<Company>({
        resolver: yupResolver(registerPageSchema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: Company) => {
        setLoading(true);
        formData.domain = `${formData.domain}.${APPC.APP_DOMAIN}`;
        CompanyApi.postCompanyRegister<Company, Company>(formData)
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("ppub.Security.RegisterPage:toast.success"),
                        status: "success",
                    });
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Box maxW={"28rem"} py={{ base: 8, sm: 8 }} mx={"auto"}>
                <Center>
                    <Image
                        w={"60%"}
                        src={logoSrc}
                        alt="WORKPRIO"
                        loading="lazy"
                    />
                </Center>
            </Box>
            <Box
                maxW={"28rem"}
                py={{ base: 8, sm: 8 }}
                px={{ base: 4, sm: 10 }}
                borderRadius={"0.5rem"}
                boxShadow={"0px 4px 36px 0px #96969629"}
                mx={"auto"}
                bg={"#fff"}
            >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {loading && <AppLoader />}
                    <VStack gap={5} alignItems={"stretch"}>
                        <AppFormControl
                            label={t("ent.Company:name.label")}
                            isInvalid={!!errors.name}
                            message={errors.name?.message}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.Company:name.placeholder")}
                                {...register("name")}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Company:domain.label")}
                            isInvalid={!!errors.domain}
                            message={errors.domain?.message}
                        >
                            <InputGroup>
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.Company:domain.placeholder"
                                    )}
                                    {...register("domain")}
                                />
                                <InputRightAddon>
                                    .{APPC.APP_DOMAIN}
                                </InputRightAddon>
                            </InputGroup>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:firstName.label")}
                            isInvalid={!!errors?.users?.[0]?.["firstName"]}
                            message={errors?.users?.[0]?.["firstName"]?.message}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.User:firstName.placeholder"
                                )}
                                {...register("users.0.firstName")}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:lastName.label")}
                            isInvalid={!!errors?.users?.[0]?.["lastName"]}
                            message={errors?.users?.[0]?.["lastName"]?.message}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:lastName.placeholder")}
                                {...register("users.0.lastName")}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:email.label")}
                            isInvalid={!!errors?.users?.[0]?.["email"]}
                            message={errors?.users?.[0]?.["email"]?.message}
                        >
                            <Input
                                type="email"
                                placeholder={t("ent.User:email.placeholder")}
                                {...register("users.0.email")}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:plainPassword.label")}
                            isInvalid={!!errors?.users?.[0]?.["plainPassword"]}
                            message={
                                errors?.users?.[0]?.["plainPassword"]?.message
                            }
                        >
                            <Input
                                type="password"
                                placeholder={t(
                                    "ent.User:plainPassword.placeholder"
                                )}
                                {...register("users.0.plainPassword")}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:plainPasswordConfirm.label")}
                            isInvalid={
                                !!errors?.users?.[0]?.["plainPasswordConfirm"]
                            }
                            message={
                                errors?.users?.[0]?.["plainPasswordConfirm"]
                                    ?.message
                            }
                        >
                            <Input
                                type="password"
                                placeholder={t(
                                    "ent.User:plainPasswordConfirm.placeholder"
                                )}
                                {...register("users.0.plainPasswordConfirm")}
                            />
                        </AppFormControl>
                        <Button
                            isDisabled={isSubmitting || loading}
                            type="submit"
                        >
                            {t("ppub.Security.RegisterPage:button.register")}
                        </Button>
                        <HStack justifyContent={"center"}>
                            <Button
                                as={NavLink}
                                to={cPath("PUBLIC.SECURITY.LOGIN_PAGE")}
                                size="sm"
                                variant={"secondary"}
                            >
                                {t(
                                    "ppub.Security.RegisterPage:button.backToLogin"
                                )}
                            </Button>
                        </HStack>
                    </VStack>
                </form>
            </Box>
        </>
    );
};
