import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";

export class User extends BaseEntity {
    public email: string = "";
    public roles: string[] = [];
    public userType: string = "";
    public plainPassword: string = "";
    public plainPasswordConfirm: string = "";
    public changePwdCode?: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public fullName: string = "";
    public company: string | Company = "";

    constructor(model: Partial<User> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PUser = Partial<User>;
