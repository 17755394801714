import {
    default as Axios,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource,
} from "axios";
import { axiosInstance } from "./AxiosInstance";
import { RequestParamsType, RequestPayloadDataType } from "./types";

type HttpMethod =
    | "GET"
    | "DELETE"
    | "HEAD"
    | "OPTIONS"
    | "POST"
    | "PUT"
    | "PATCH"
    | "PURGE"
    | "LINK"
    | "UNLINK";

export abstract class BaseApi {
    protected static async request<R, P>(
        method: HttpMethod,
        url: string,
        data?: RequestPayloadDataType | P | string,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.request<R>({
            method,
            url,
            data,
            params,
            ...config,
        });
    }

    public static createCancelTokenSource(): CancelTokenSource {
        return Axios.CancelToken.source();
    }
}
