import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContainerState, useTrans } from "../../../hooks";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
    useToast,
} from "@chakra-ui/react";
import { AppIcon, AppLoader, AppPageHeader } from "../../../components";
import { TaskList as ListComponent } from "./TaskList";
import { TaskForm as FormComponent } from "./TaskForm";
import { AppSearch } from "../../../containers";
import AsyncSelect from "react-select/async";
import { Project, ProjectApi } from "../../../apis";

interface Option {
    value: number;
    label: string;
}

export const TaskPage = () => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const { id } = useParams();
    const formContainerState = useFormContainerState();

    // state & const
    const [search, setSearch] = useState("");
    const [loadingProject, setLoadingProject] = useState(true);
    const [projectData, setProjectData] = useState<Option[]>([]);
    const [selectedProject, setSelectedProject] = useState<Option | null>();

    const fetchProjectData = () => {
        setLoadingProject(true);
        const params = {
            [`order[name]`]: "asc",
            pagination: false,
        };
        if (selectedProject?.value) {
            params["project"] = selectedProject?.value;
        }

        ProjectApi.getCollection<Project>(1, params)
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    const data = response?.items?.map((e) => {
                        return { value: e.id, label: e.name };
                    });
                    formContainerState.setEntityData({
                        projectId: data[0]?.value ?? 0,
                    });
                    setProjectData(data);
                    setSelectedProject(data[0]);
                }
            })
            .finally(() => setLoadingProject(false));
    };
    useEffect(() => {
        fetchProjectData();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            formContainerState.setEntityData({
                projectId: selectedProject?.value ?? 0,
            });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    if (loadingProject) {
        return <AppLoader />;
    }

    return (
        <>
            <AppPageHeader title={t("padm.TaskPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    <Center height="2.1875rem">
                        <Divider orientation="vertical" />
                    </Center>
                    <Button
                        variant={"primary"}
                        onClick={() => formContainerState.open(0)}
                    >
                        <AppIcon name="ics-plus-circle" w="1rem" />
                        <Hide below="md">
                            <Text ml={2}>
                                {t("padm.TaskPage:button.create")}
                            </Text>
                        </Hide>
                    </Button>
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <HStack w={"full"}>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions={projectData}
                        value={selectedProject}
                        onChange={(e) => setSelectedProject(e)}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                    ? "black"
                                    : "#AFAFAF",
                                height: "41px",
                                minWidth: "350px",
                                ":hover": {
                                    borderColor: "#727272",
                                },
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: 14,
                                color: "#000",
                                paddingLeft: 4,
                            }),
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: 14,
                                marginTop: 10,
                                marginBottom: 10,
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                ...baseStyles,
                                display: "none",
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                color: "#000",
                                ":hover": {
                                    color: "#000",
                                },
                                svg: {
                                    height: "18px",
                                    width: "18px",
                                    strokeWidth: "0.1",
                                },
                            }),
                            option: (baseStyles) => ({
                                ...baseStyles,
                                paddingLeft: 15,
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#F1F1F1",
                                primary: "#000",
                            },
                        })}
                    />
                </HStack>
                <ListComponent
                    formContainerState={formContainerState}
                    search={search}
                />
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent formContainerState={formContainerState} />
            )}
        </>
    );
};
