import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    mt: 8,
    fontFamily: "Inter Regular, sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    color: "#000000",
});

export const FormErrorMessage = defineStyleConfig({
    baseStyle,
});
