export const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
export const APP_SUBDOMAIN = process.env.REACT_APP_SUBDOMAIN;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const APP_FULLDOMAIN = `${APP_SUBDOMAIN}.${APP_DOMAIN}`;
export const APP_BASEPATH = `${APP_PROTOCOL}://${APP_FULLDOMAIN}`;
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50, 100];
export const FORM_CONTAINER = "drawer";

export const BE = {
    User: {
        ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
        ROLE_COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
        ROLE_ADMIN: "ROLE_ADMIN",
        ROLE_USER: "ROLE_USER",
    },
};
