import { useAuthAction, useTrans } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { useAuthData } from "../../../contexts";
import { AppFormControl, AppIcon, AppPageHeader } from "../../../components";
import { User as FormEntity, UserApi as FormEntityApi } from "../../../apis";
import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Input,
    Select,
    Text,
    VStack,
    useToast,
} from "@chakra-ui/react";

export const ProfilePage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const { user } = useAuthData();
    const { reloadUserAction } = useAuthAction();

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        if (user) {
            FormEntityApi.patchItemProfile<FormEntity, FormEntity>(
                user.id,
                formData
            ).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    reloadUserAction();
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                }
            });
        }
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={6} maxW={"600px"}>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>Personal Information</Text>
                        <Divider />
                    </Box>
                    <Avatar size="2xl" name={user?.fullName} />
                    <Button variant={"transparent"} w={"auto"}>
                        Change Avatar
                    </Button>
                    <AppFormControl label={t("ent.User:email.label")}>
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            isDisabled={true}
                            defaultValue={user?.email}
                        />
                    </AppFormControl>
                    <HStack w="100%">
                        <Button w="50%" variant={"destructive"}>
                            <AppIcon name="icl-mail" w="1rem" />
                            <Text ml="2">Change Email</Text>
                        </Button>
                        <Button w="50%" variant={"destructive"}>
                            <AppIcon name="icl-key-alt" />
                            <Text ml="2">Change Password</Text>
                        </Button>
                    </HStack>
                    <AppFormControl
                        label={t("ent.User:firstName.label")}
                        isInvalid={!!errors?.firstName}
                        message={errors?.firstName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:firstName.placeholder")}
                            {...register("firstName")}
                            defaultValue={user?.firstName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:lastName.label")}
                        isInvalid={!!errors?.lastName}
                        message={errors?.lastName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:lastName.placeholder")}
                            {...register("lastName")}
                            defaultValue={user?.lastName}
                        />
                    </AppFormControl>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>Preferences</Text>
                        <Divider />
                    </Box>
                    <AppFormControl label={"Language"}>
                        <Select>
                            <option value="en" selected>
                                English
                            </option>
                            <option value="hi">Hindi</option>
                            <option value="gu">Gujarati</option>
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Timezone"}>
                        <Select>
                            <option value="GMT+5:30" selected>
                                GMT+5:30 Asia/Kolkata
                            </option>
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Date Format"}>
                        <Select>
                            <option value="DD/MM/YYYY" selected>
                                DD/MM/YYYY
                            </option>
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Time Format"}>
                        <Select>
                            <option value="12 Hours" selected>
                                12 Hours
                            </option>
                            <option value="24 Hours">24 Hours</option>
                        </Select>
                    </AppFormControl>
                    <Box alignSelf={"end"}>
                        <Button type="submit" form="app-form">
                            {t("cmn:button.save")}
                        </Button>
                    </Box>
                </VStack>
            </form>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.ProfilePage:text.profile")}
            ></AppPageHeader>
            <Box p={6}>{renderUserForm()}</Box>
        </>
    );
};
