import { useState } from "react";
import { ActionType, ITableInstance, useTable } from "ka-table";
import { APPC } from "../config";

export type AppTableReturnType = {
    table: ITableInstance;
    pageIndex: number;
    pagesCount: number;
    setPagesCount: (v: number) => void;
    pageSize: number;
    sortColumn: string;
    sortOrder: string;
    getSelectRow: (pageIndex: number, rowId: number | string) => boolean;
    setSelectRow: (pageIndex: number, rowId: number | string) => void;
    getSelectAll: (pageIndex: number) => string;
    setSelectAll: (pageIndex: number) => void;
    getSelectTotal: () => number;
};

export type AppTableSelectType = {
    pageIndex: number;
    selectAll: "ALL" | "NONE" | "SOME";
    selectRow: (number | string)[];
};

export function useAppTable(
    defaultSortColumn: string,
    defaultSortOrder: string = "asc"
): AppTableReturnType {
    const [pageIndex, setPageIndex] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);
    const [pageSize, setPageSize] = useState(APPC.PAGE_SIZE);
    const [sortColumn, setSortColumn] = useState(defaultSortColumn);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [select, setSelect] = useState<AppTableSelectType[]>([]);

    const table = useTable({
        onDispatch: async (action) => {
            if (action.type === ActionType.UpdatePageIndex) {
                setPageIndex(action.pageIndex);
            } else if (action.type === ActionType.UpdatePageSize) {
                setPageSize(action.pageSize);
            } else if (action.type === ActionType.UpdateSortDirection) {
                if (action.columnKey === sortColumn) {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                } else {
                    setSortColumn(action.columnKey);
                    setSortOrder("asc");
                }
            }
        },
    });

    const getSelectOnPage = (pageIndex: number) => {
        return select?.filter((s) => s.pageIndex === pageIndex)[0];
    };
    const getSelectIndexOnPage = (pageIndex: number) => {
        return select?.findIndex((e) => e.pageIndex === pageIndex);
    };

    const getSelectRow = (
        pageIndex: number,
        rowId: number | string
    ): boolean => {
        const p = getSelectOnPage(pageIndex);
        return !!(p && p?.selectRow?.includes(rowId));
    };
    const setSelectRow = (pageIndex: number, rowId: number | string): void => {
        let p = getSelectOnPage(pageIndex);
        const tmpSelect = select;
        if (p) {
            tmpSelect.splice(getSelectIndexOnPage(pageIndex), 1);
            p.selectRow.includes(rowId)
                ? p.selectRow.splice(p.selectRow.indexOf(rowId), 1)
                : p.selectRow.push(rowId);
        } else {
            p = {
                pageIndex: pageIndex,
                selectAll: "SOME",
                selectRow: [rowId],
            };
        }

        p.selectAll =
            p.selectRow.length === pageSize ||
            p.selectRow.length === table.props.data?.length
                ? "ALL"
                : p.selectRow.length > 0
                  ? "SOME"
                  : "NONE";

        setSelect([...tmpSelect, p]);
    };
    const getSelectAll = (pageIndex: number): string => {
        const p = getSelectOnPage(pageIndex);
        return p?.selectAll;
    };
    const setSelectAll = (pageIndex: number): void => {
        let p = getSelectOnPage(pageIndex);
        if (p) {
            const tmpSelect = select;
            tmpSelect.splice(getSelectIndexOnPage(pageIndex), 1);
            setSelect([...tmpSelect]);
        } else {
            const rowIds = table.props.data?.map((e) => e?.id) as number[];
            p = {
                pageIndex: pageIndex,
                selectAll: "ALL",
                selectRow: rowIds,
            };
            setSelect([...select, p]);
        }
    };
    const getSelectTotal = (): number => {
        let total = 0;
        select?.map((e) => (total = total + e?.selectRow?.length));
        return total;
    };

    return {
        table,
        pageIndex,
        pagesCount,
        setPagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        getSelectRow,
        setSelectRow,
        getSelectAll,
        setSelectAll,
        getSelectTotal,
    };
}
