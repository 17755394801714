import { useTrans } from "../../../hooks";
import { CheckIcon } from "@chakra-ui/icons";
import {
    AppIcon,
    AppTaskColumn,
    AppPageHeader,
    AppTab,
} from "../../../components";
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Box,
    HStack,
    Badge,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    FormHelperText,
    InputGroup,
    Input,
    InputLeftElement,
    InputRightElement,
    Hide,
    Flex,
} from "@chakra-ui/react";

export const DashboardPage = () => {
    const { t } = useTrans();
    return (
        <>
            <AppPageHeader
                title={t("padm.DashboardPage:text.dashboard")}
            ></AppPageHeader>
            <Hide>
                <AppPageHeader title="Dashboard" />
                <AppTab />

                <Tabs
                    ml={"100px"}
                    mt={"50px"}
                    position="relative"
                    variant="app"
                >
                    <TabList gap={"8px"}>
                        <Tab>
                            <AppIcon name={"icl-list"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                One
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-clipboard"} w="1rem" />

                            <Text ml={"8px"} as={"span"}>
                                Two
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-chart-table"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                Three
                            </Text>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <p>one!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>two!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>three!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>

                <Box ml={"100px"} mt={"50px"} w={"400px"}>
                    <FormControl>
                        <FormLabel>Number</FormLabel>
                        <NumberInput>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </Box>

                <HStack ml={"100px"} mt={"50px"}>
                    <Badge variant={"green"}>Badge</Badge>
                    <Badge variant={"blue"}>Badge</Badge>
                    <Badge variant={"red"}>Badge</Badge>
                    <Badge variant={"primary"}>Badge</Badge>
                    <Badge variant={"secondary"}>Badge</Badge>
                    <Badge variant={"green"} size={"lg"}>
                        Badge
                    </Badge>
                    <Button variant={"primary"} size={"xs"}>
                        Button
                    </Button>
                    <Button variant={"secondary"} size={"sm"}>
                        Button
                    </Button>
                    <Button variant={"outline"} size={"md"}>
                        Button
                    </Button>
                    <Button variant={"destructive"} size={"lg"}>
                        Button
                    </Button>
                </HStack>

                <HStack ml={"100px"} mt={"50px"}>
                    <Badge colorScheme="green">Badge</Badge>
                    <Badge colorScheme="red">Badge</Badge>
                </HStack>

                <Checkbox size={"sm"}>123</Checkbox>
                <FormControl>
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" />
                    <FormHelperText>
                        Well never share your email.
                    </FormHelperText>
                </FormControl>

                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                    >
                        $
                    </InputLeftElement>
                    <Input placeholder="Enter amount" />
                    <InputRightElement>
                        <CheckIcon color="green.500" />
                    </InputRightElement>
                </InputGroup>
            </Hide>
            <Flex
                marginTop={8}
                gap={2}
                padding={6}
                flexWrap={"wrap"}
                flexGrow={1}
            >
                <Box>
                    <AppTaskColumn />
                </Box>
                <Box>
                    <AppTaskColumn />
                </Box>
                <Box>
                    <AppTaskColumn />
                </Box>
                <Box>
                    <AppTaskColumn />
                </Box>
                <Box>
                    <AppTaskColumn />
                </Box>
            </Flex>
        </>
    );
};
