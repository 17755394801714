import { Avatar, Flex, HStack, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

export const AppTaskCard = () => {
    return (
        <>
            <Flex
                w={"267px"}
                direction={"column"}
                p={"1.125rem"}
                gap={"1rem"}
                border={"1px solid #E4E4E4"}
                borderTopWidth={"5px"}
                borderRadius={"4px"}
                bg={"white"}
                justifyContent={"space-between"}
            >
                <Tooltip
                    hasArrow
                    label="react-select UI adjustments as per the theme as soon as
                        possible high priority"
                    bg="#444"
                    color="white"
                    placement="auto"
                    openDelay={1000}
                >
                    <Text noOfLines={2} textStyle="mediumSm">
                        react-select UI adjustments as per the theme as soon as
                        possible high priority
                    </Text>
                </Tooltip>
                <HStack justifyContent={"space-between"}>
                    <HStack gap={"0.5rem"}>
                        <AppIcon name="icl-calendar" w="1rem" />
                        <Text textStyle={"regularSm"} color="#727272">
                            19 Sep 2024
                        </Text>
                    </HStack>
                    <Spacer />
                    <HStack gap={"0.25rem"}>
                        <AppIcon name="icl-list" w="1rem" />
                        <Text textStyle={"regularSm"} color="#727272">
                            3/8
                        </Text>
                    </HStack>
                </HStack>
                <HStack gap={"0.75rem"}>
                    <Tooltip
                        hasArrow
                        label="Dan Abrahmov"
                        bg="#444"
                        color="white"
                        placement="auto"
                        openDelay={1000}
                    >
                        <Avatar
                            name="Dan Abrahmov"
                            src="https://bit.ly/dan-abramov"
                            size="sm"
                        />
                    </Tooltip>
                    <Spacer />
                    <HStack gap={"0.25rem"}>
                        <AppIcon name="icl-paperclip" w="1rem" />
                        <Text textStyle={"regularSm"} color="#727272">
                            99
                        </Text>
                    </HStack>
                    <HStack gap={"0.25rem"}>
                        <AppIcon name="icl-comment-bubble" w="1rem" />
                        <Text textStyle={"regularSm"} color="#727272">
                            999
                        </Text>
                    </HStack>
                </HStack>
            </Flex>
        </>
    );
};
