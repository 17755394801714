import { useState } from "react";
import { useDebounce, useTrans } from "../hooks";
import {
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react";
import { AppIcon } from "../components";

type AppSearchProps = {
    onSearch: (v: string) => void;
};

export const AppSearch = ({ onSearch }: AppSearchProps) => {
    const { t } = useTrans();

    const [value, setValue] = useState("");

    const debounceSearch = useDebounce(() => {
        onSearch(value);
    }, 300);

    const handleSearch = (v) => {
        setValue(`${v}`);
        debounceSearch();
    };
    return (
        <InputGroup minW={"11.25rem"}>
            <InputLeftElement pointerEvents="none">
                <AppIcon name="ics-search" />
            </InputLeftElement>
            <Input
                type="text"
                placeholder={t("com:AppSearch:search:placeholder")}
                value={value}
                onChange={(e) => handleSearch(e.currentTarget.value)}
                maxLength={50}
            />
            <InputRightElement>
                <Button
                    display={value ? "block" : "none"}
                    variant={"transparent"}
                    size={"sm"}
                    className="btn-icon-sm"
                    onClick={() => handleSearch("")}
                >
                    <AppIcon name="icl-x" w="1rem" />
                </Button>
            </InputRightElement>
        </InputGroup>
    );
};
