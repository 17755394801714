import { useTrans } from "./useTrans";
import { useAuthData } from "../contexts";
import { cPath } from "../config";
import { NavItem } from "../containers/AppNav/AppNav";

export const useNavItems = (type: string = "main"): NavItem[] => {
    const { t } = useTrans();
    const { grant } = useAuthData();

    const userNavItems: NavItem[] = [
        {
            label: t("nav:profile"),
            url: cPath("ADMIN.PROFILE_PAGE"),
            iconName: "icl-address-card",
        },
        {
            label: t("nav:logout"),
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
    ];

    const mainNavItems: NavItem[] = [
        {
            label: t("nav:dashboard"),
            url: cPath("ADMIN.DASHBOARD_PAGE"),
            iconName: "icl-view-quilt",
            divider: true,
        },
        {
            label: t("nav:timesheet"),
            url: cPath("ADMIN.PROJECT_PAGE"),
            iconName: "icl-spreadsheet",
            divider: true,
        },
        /*
        {
            label: t("nav:company"),
            url: cPath("ADMIN.COMPANY_PAGE"),
            iconName: "icl-building-business",
        },
        {
            label: t("nav:user"),
            url: cPath("ADMIN.USER_PAGE"),
            iconName: "icl-people",
        },
        {
            label: t("nav:workspace"),
            url: cPath("ADMIN.WORKSPACE_PAGE"),
            iconName: "icl-factory",
        },
        {
            label: "Analysis",
            url: "/admin/analysis",
            iconName: "icl-education",
        },
        {
            label: "Documents",
            iconName: "icl-spa",
            children: [
                {
                    label: "Resumes",
                    url: "/admin/resumes",
                    iconName: "icl-vote",
                },
                {
                    label: "Cover Letter",
                    url: "/admin/coverletter",
                    iconName: "icl-bookmark",
                },
                {
                    label: "Personal",
                    url: "/admin/personal",
                    iconName: "icl-envelope-closed",
                },
                {
                    label: "Education",
                    url: "/admin/education",
                    iconName: "icl-document",
                },
            ],
            divider: true,
        },
        {
            label: "Movies",
            iconName: "icl-apple",
            children: [
                {
                    label: "Dangal",
                    url: "/admin/dangal",
                    iconName: "icl-birthday-cake",
                },
                {
                    label: "Swades",
                    url: "/admin/swades",
                    iconName: "icl-coffee",
                },
                {
                    label: "12th Fail",
                    url: "/admin/twelethfail",
                    iconName: "icl-dinner",
                },
                {
                    label: "War",
                    url: "/admin/war",
                    iconName: "icl-drink",
                },
            ],
        },
        {
            label: "History",
            url: "/admin/history",
            iconName: "icl-drink-alcohol",
            divider: true,
        },
        {
            label: "Favorites",
            url: "/admin/favourites",
            iconName: "icl-mug",
        },
        {
            label: "Logout",
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
        */
    ];

    mainNavItems.push({
        label: t("nav:projectManagement"),
    });
    mainNavItems.push({
        label: t("nav:project"),
        url: cPath("ADMIN.PROJECT_PAGE"),
        iconName: "icl-developer-board",
    });
    mainNavItems.push({
        label: t("nav:task"),
        url: cPath("ADMIN.TASK_PAGE"),
        iconName: "icl-notes",
        divider: true,
    });

    mainNavItems.push({
        label: t("nav:administration"),
    });

    if (grant.isSuperAdmin) {
        mainNavItems.push({
            label: t("nav:company"),
            url: cPath("ADMIN.COMPANY_PAGE"),
            iconName: "icl-building-business",
        });
        mainNavItems.push({
            label: t("nav:user"),
            url: cPath("ADMIN.USER_PAGE"),
            iconName: "icl-id-badge",
        });
    }

    if (grant.isCompanyAdmin) {
        mainNavItems.push({
            label: t("nav:user"),
            url: cPath("ADMIN.USER_PAGE"),
            iconName: "icl-id-badge",
        });
        mainNavItems.push({
            label: t("nav:workspace"),
            url: cPath("ADMIN.WORKSPACE_PAGE"),
            iconName: "icl-factory",
        });
    }

    return type === "user" ? userNavItems : mainNavItems;
};
