import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class UserApi extends EntityApi {
    protected static GET_COLLECTION = "/api/users";

    protected static POST_COLLECTION = "/api/users";

    protected static GET_ITEM = "/api/users/{id}";

    protected static PUT_ITEM = "/api/users/{id}";

    protected static PATCH_ITEM = "/api/users/{id}";

    protected static DELETE_ITEM = "/api/users/{id}";

    protected static POST_USER_EXIST = "/user-exist";

    protected static POST_USER_CHANGE_PASSWORD_REQUEST =
        "/user-change-password-request";

    protected static POST_USER_SET_PASSWORD = "/user-set-password";

    protected static PATCH_USER_ITEM_PROFILE = "/api/users/{id}/profile";

    protected static PUBLIC_GET_COLLECTION = "/public-api/users/get";

    public static async publicGetCollection<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.PUBLIC_GET_COLLECTION };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async postUserExist<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_USER_EXIST };
        return this.postCollection(entity, extraParams);
    }

    public static async postUserChangePasswordRequest<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.POST_USER_CHANGE_PASSWORD_REQUEST,
        };
        return this.postCollection(entity, extraParams);
    }

    public static async postUserSetPassword<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.POST_USER_SET_PASSWORD,
        };
        return this.postCollection(entity, extraParams);
    }

    public static async patchItemProfile<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.PATCH_USER_ITEM_PROFILE };
        return this.patchItem(id, entity, extraParams);
    }
}
