import { createContext, useContext, useEffect, useState } from "react";
import { CompanyApi, PCompany } from "../apis";
import { APPC } from "../config";

interface AppContextState {
    status: "LOADED" | "LOADING" | "ERROR";
    company?: PCompany;
}

const AppContext = createContext<AppContextState | null>(null);

export const AppProvider = (props: { children }) => {
    const [state, setState] = useState<AppContextState>({
        status: "LOADING",
    });

    useEffect(() => {
        setState({
            status: "LOADING",
        });
        (async (): Promise<void> => {
            const { error, response } =
                await CompanyApi.getCollectionMyCompany<PCompany>();
            if (error) {
                setState({ status: "ERROR" });
            } else {
                if (response && response?.totalItems > 0 && response.items[0]) {
                    setState({
                        company: response.items[0],
                        status: "LOADED",
                    });
                } else {
                    document.location.href = `${APPC.APP_BASEPATH}?HNF=${window.location.host}`;
                }
            }
        })();
    }, [setState]);

    return (
        <AppContext.Provider value={state}>
            {props.children}
        </AppContext.Provider>
    );
};

export const useAppData = (): AppContextState => {
    const context = useContext(AppContext);
    if (context === null) {
        throw new Error("useAppData must be used within a AppProvider");
    }
    return context;
};
