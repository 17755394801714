import { FC, useEffect, useRef, useState } from "react";
import {
    FormContainerStateReturnType,
    useAppTable,
    useTrans,
} from "../../../hooks";
import { useAuthData } from "../../../contexts";
import { Canceler } from "axios";
import {
    HStack,
    useToast,
    Text,
    useDisclosure,
    Badge,
    Checkbox,
    Flex,
    Box,
} from "@chakra-ui/react";
import { User as ListEntity, UserApi as ListEntityApi } from "../../../apis";
import { DataType, SortingMode, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { APPC } from "../../../config";
import { Column } from "ka-table/models";

export const UserList: FC<{
    formContainerState: FormContainerStateReturnType;
    search?: string;
}> = ({ formContainerState, search }) => {
    // hook
    const { t } = useTrans();
    const { grant } = useAuthData();
    const toast = useToast();
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();

    // state & const
    const [loading, setLoading] = useState(true);
    const [selectId, setSelectId] = useState(0);
    const [list, setList] = useState<ListEntity[]>([]);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // table
    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
        getSelectRow,
        setSelectRow,
        getSelectAll,
        setSelectAll,
        getSelectTotal,
    } = useAppTable("firstName");

    const columns: Column[] = [
        {
            key: "selection-cell",
            isFilterable: false,
            width: 50,
        },
        {
            key: "firstName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "email",
            title: t("ent.User:email.label"),
            dataType: DataType.String,
        },
        {
            key: "userType",
            title: t("ent.User:userType.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "action",
            title: "",
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 50,
            isSortable: false,
        },
    ];

    if (grant.isSuperAdmin) {
        columns.splice(3, 0, {
            key: "company.name",
            title: t("ent.Company:name.label"),
            dataType: DataType.String,
        });
    }

    const deleteHandler = async () => {
        if (selectId < 1) {
            return;
        }
        setLoading(true);
        ListEntityApi.deleteItem(selectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchListData = () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["firstName"] = search;
        }

        ListEntityApi.getCollection<ListEntity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
        {
            key: "delete",
            label: t("cmn:option.delete"),
            iconName: "icl-trash",
        },
    ];

    const filterActionOptions = (props: ICellTextProps) => {
        return actionOptions.map((o) => {
            if (o.key === "delete") {
                const isDisabled =
                    props?.rowData?.userType ===
                        APPC.BE.User.ROLE_SUPER_ADMIN ||
                    (grant.isCompanyAdmin
                        ? props?.rowData?.userType ===
                          APPC.BE.User.ROLE_COMPANY_ADMIN
                        : false);
                o.isDisabled = isDisabled;
            }
            return o;
        });
    };

    const actionClickHandler = (key: string, data?: ListEntity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "edit":
                formContainerState.open(data?.id);
                break;
            case "delete":
                setSelectId(data?.id);
                onOpenDelete();
                break;
        }
    };

    const SelectionCell = ({ rowKeyValue }: ICellTextProps) => (
        <Checkbox
            isChecked={getSelectRow(pageIndex, rowKeyValue)}
            onChange={() => setSelectRow(pageIndex, rowKeyValue)}
        />
    );

    const SelectionHeader = () => {
        return (
            <Checkbox
                isChecked={getSelectAll(pageIndex) === "ALL"}
                isIndeterminate={getSelectAll(pageIndex) === "SOME"}
                onChange={() => setSelectAll(pageIndex)}
            />
        );
    };

    return (
        <>
            {getSelectTotal() > 0 && (
                <Flex alignSelf={"flex-start"}>
                    <Box>{getSelectTotal()} User Selected</Box>
                </Flex>
            )}
            <KaTable
                table={table}
                columns={columns}
                data={list || []}
                loading={{
                    enabled: loading,
                }}
                paging={{
                    enabled: true,
                    pageSizes: APPC.PAGE_SIZES,
                    pageSize,
                    pageIndex,
                    pagesCount,
                }}
                rowKeyField={"id"}
                sortingMode={SortingMode.SingleRemote}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "firstName":
                                    return (
                                        <Text
                                            textStyle={"mediumSm"}
                                            color={"black"}
                                        >
                                            {props?.rowData?.fullName}
                                        </Text>
                                    );
                                case "company.name":
                                    return (
                                        <a
                                            href={`${APPC.APP_PROTOCOL}://${props?.rowData?.company?.domain}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {props?.rowData?.company?.name}
                                        </a>
                                    );
                                case "userType":
                                    return (
                                        <Badge variant={"secondary"}>
                                            {t(
                                                `con.User:${props?.rowData?.userType}`
                                            )}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox
                                                options={filterActionOptions(
                                                    props
                                                )}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                                case "selection-cell":
                                    return <SelectionCell {...props} />;
                            }
                        },
                    },
                    headCell: {
                        content: (props) => {
                            if (props.column.key === "selection-cell") {
                                return <SelectionHeader />;
                            }
                        },
                    },
                }}
            />

            {isOpenDelete && selectId && (
                <AppAlertDelete
                    isOpenDelete
                    deleteHandler={deleteHandler}
                    onCloseDelete={onCloseDelete}
                />
            )}
        </>
    );
};
