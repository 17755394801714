import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    useToast,
    InputRightAddon,
    InputGroup,
    Switch,
} from "@chakra-ui/react";
import {
    Company as FormEntity,
    CompanyApi as FormEntityApi,
} from "../../../apis";
import { AppFormControl, AppLoader } from "../../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const CompanyForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FormEntity>(new FormEntity());
    const isEditMode: boolean = !!formContainerState.entityId;

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t, isEditMode)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        setLoading(true);
        if (!isEditMode) {
            formData.domain = `${formData.domain}.${APPC.APP_DOMAIN}`;
        }
        FormEntityApi.createOrUpdate<FormEntity, FormEntity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        FormEntityApi.getItem<FormEntity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return (
        <AppFormContainer
            title={t("padm.CompanyForm:text.company")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            {loading ? (
                <AppLoader />
            ) : (
                <form
                    id="app-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmitHandler)();
                    }}
                >
                    <VStack gap={5} alignItems={"stretch"}>
                        <AppFormControl
                            label={t("ent.Company:name.label")}
                            isInvalid={!!errors.name}
                            message={errors.name?.message}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.Company:name.placeholder")}
                                {...register("name")}
                                defaultValue={data.name}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Company:domain.label")}
                            isInvalid={!!errors.domain}
                            message={errors.domain?.message}
                        >
                            <InputGroup>
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.Company:domain.placeholder"
                                    )}
                                    {...register("domain")}
                                    defaultValue={data.domain}
                                    disabled={isEditMode}
                                />
                                {!isEditMode && (
                                    <InputRightAddon>
                                        .{APPC.APP_DOMAIN}
                                    </InputRightAddon>
                                )}
                            </InputGroup>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Company:isActive.label")}
                            isInvalid={!!errors.isActive}
                            message={errors.isActive?.message}
                        >
                            <Switch
                                {...register("isActive")}
                                defaultChecked={
                                    isEditMode ? !!data.isActive : true
                                }
                            />
                        </AppFormControl>
                        {!isEditMode && (
                            <>
                                <AppFormControl
                                    label={t("ent.User:firstName.label")}
                                    isInvalid={
                                        !!errors?.users?.[0]?.["firstName"]
                                    }
                                    message={
                                        errors?.users?.[0]?.["firstName"]
                                            ?.message
                                    }
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.User:firstName.placeholder"
                                        )}
                                        {...register("users.0.firstName")}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:lastName.label")}
                                    isInvalid={
                                        !!errors?.users?.[0]?.["lastName"]
                                    }
                                    message={
                                        errors?.users?.[0]?.["lastName"]
                                            ?.message
                                    }
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.User:lastName.placeholder"
                                        )}
                                        {...register("users.0.lastName")}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:email.label")}
                                    isInvalid={!!errors?.users?.[0]?.["email"]}
                                    message={
                                        errors?.users?.[0]?.["email"]?.message
                                    }
                                >
                                    <Input
                                        type="email"
                                        placeholder={t(
                                            "ent.User:email.placeholder"
                                        )}
                                        {...register("users.0.email")}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:plainPassword.label")}
                                    isInvalid={
                                        !!errors?.users?.[0]?.["plainPassword"]
                                    }
                                    message={
                                        errors?.users?.[0]?.["plainPassword"]
                                            ?.message
                                    }
                                >
                                    <Input
                                        type="password"
                                        placeholder={t(
                                            "ent.User:plainPassword.placeholder"
                                        )}
                                        {...register("users.0.plainPassword")}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t(
                                        "ent.User:plainPasswordConfirm.label"
                                    )}
                                    isInvalid={
                                        !!errors?.users?.[0]?.[
                                            "plainPasswordConfirm"
                                        ]
                                    }
                                    message={
                                        errors?.users?.[0]?.[
                                            "plainPasswordConfirm"
                                        ]?.message
                                    }
                                >
                                    <Input
                                        type="password"
                                        placeholder={t(
                                            "ent.User:plainPasswordConfirm.placeholder"
                                        )}
                                        {...register(
                                            "users.0.plainPasswordConfirm"
                                        )}
                                    />
                                </AppFormControl>
                            </>
                        )}
                    </VStack>
                </form>
            )}
        </AppFormContainer>
    );
};
