import { useRef, memo } from "react";
import { useLocation } from "react-router-dom";
import { VStack } from "@chakra-ui/react";
import { ButtonVariants } from "../../theme/components/button";
import AppNavItem from "./AppNavItem";
import AppNavDropdownItem from "./AppNavDropdownItem";
import { AppNavItemHead } from "./AppNavItemHead";

export interface NavItem {
    label: string;
    url?: string;
    iconName?: string;
    children?: Pick<NavItem, "label" | "url" | "iconName">[];
    divider?: boolean;
}

interface AppNavProps {
    navItems: NavItem[];
    variant?: ButtonVariants;
    onlyIcon?: boolean;
}

const AppNav = ({
    navItems,
    variant = "transparent",
    onlyIcon = false,
}: AppNavProps) => {
    const location = useLocation();

    const dropdownContainerRef = useRef<(HTMLDivElement | null)[]>([]);
    const handleDropdownClick = (i: number) => {
        dropdownContainerRef.current.forEach((element, index) => {
            const ddContainer = element?.querySelectorAll(
                ".dropdown-container"
            )[0];
            const arrowIcon = element?.querySelectorAll(
                ".icl-chevron-bottom-alt"
            )[0];
            if (index !== i) {
                ddContainer?.classList.add("close-dropdown");
                arrowIcon?.classList.remove("rotate-180");
            } else {
                ddContainer?.classList.toggle("close-dropdown");
                arrowIcon?.classList.toggle("rotate-180");
            }
        });
    };

    return (
        <VStack alignItems="stretch" gap={1} px={6} pt={6} className="app-nav">
            {navItems.map((navItem, index) => {
                const isActive = location.pathname === navItem?.url;
                let subActive = -1;
                navItem.children?.forEach((child, index) => {
                    if (location.pathname === child.url) {
                        subActive = index;
                    }
                });

                return navItem.children ? (
                    <div
                        ref={(el) => (dropdownContainerRef.current[index] = el)}
                        key={index}
                    >
                        <AppNavDropdownItem
                            index={index}
                            navDropdownItem={navItem}
                            variant={variant}
                            handleDropdownClick={handleDropdownClick}
                            onlyIcon={onlyIcon}
                            key={index}
                            subActive={subActive}
                        />
                    </div>
                ) : navItem.url ? (
                    <AppNavItem
                        navItem={navItem}
                        variant={variant}
                        onlyIcon={onlyIcon}
                        key={index}
                        isActive={isActive}
                    />
                ) : onlyIcon ? (
                    <></>
                ) : (
                    <AppNavItemHead navItem={navItem} key={index} />
                );
            })}
        </VStack>
    );
};

export default memo(AppNav);
