import { EntityApi } from "../../services/ApiPlatform";

export abstract class WorkspaceApi extends EntityApi {
    protected static GET_COLLECTION = "/api/workspaces";

    protected static POST_COLLECTION = "/api/workspaces";

    protected static GET_ITEM = "/api/workspaces/{id}";

    protected static PATCH_ITEM = "/api/workspaces/{id}";

    protected static DELETE_ITEM = "/api/workspaces/{id}";
}
