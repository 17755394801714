import { BaseEntity } from "./BaseEntity";
import { User } from "./User";

export class Company extends BaseEntity {
    public name: string = "";
    public domain: string = "";
    public users: string[] | User[] | null = null;
    public isVerified: boolean | null = null;
    public isActive: boolean | null = null;

    constructor(model: Partial<Company> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PCompany = Partial<Company>;
