import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { VStack, Input, useToast } from "@chakra-ui/react";
import {
    Workspace as FormEntity,
    WorkspaceApi as FormEntityApi,
} from "../../../apis";
import { AppFormControl, AppLoader } from "../../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const WorkspaceForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FormEntity>(new FormEntity());

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        setLoading(true);
        FormEntityApi.createOrUpdate<FormEntity, FormEntity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        FormEntityApi.getItem<FormEntity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return (
        <AppFormContainer
            title={t("padm.WorkspaceForm:text.workspace")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            {loading ? (
                <AppLoader />
            ) : (
                <form
                    id="app-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmitHandler)();
                    }}
                >
                    <VStack gap={5} alignItems={"stretch"}>
                        <AppFormControl
                            label={t("ent.Workspace:name.label")}
                            isInvalid={!!errors?.name}
                            message={errors?.name?.message}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.Workspace:name.placeholder"
                                )}
                                {...register("name")}
                                defaultValue={data.name}
                            />
                        </AppFormControl>
                    </VStack>
                </form>
            )}
        </AppFormContainer>
    );
};
