import { EntityApi } from "../../services/ApiPlatform";

export abstract class ProjectApi extends EntityApi {
    protected static GET_COLLECTION = "/api/projects";

    protected static POST_COLLECTION = "/api/projects";

    protected static GET_ITEM = "/api/projects/{id}";

    protected static PATCH_ITEM = "/api/projects/{id}";

    protected static DELETE_ITEM = "/api/projects/{id}";
}
